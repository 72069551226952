<script setup>
const addNewFields = defineModel('addNewFields');
defineProps(['field', 'label']);
</script>

<template>
<!--  <input-->
<!--      type="hidden"-->
<!--      :name="field"-->
<!--      value=0-->
<!--      v-model="addNewFields"-->
<!--  >-->
    <div class="form-check col-12">
      <input
          class="form-check-input col-2 col-md-1"
          value=1
          type="checkbox"
          :name="field"
          :id="field"
          v-model="addNewFields"
          :true-value=1
          :false-value=0
      >
      <label
          class="form-check-label ps-2 col-10 col-md-11"
          :for="field"
      >
        {{ label }}
      </label>
    </div>
</template>

<style scoped>

</style>