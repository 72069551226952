<script setup>
import {ref} from 'vue'
import Text from './Forms/Text.vue'
import AddNewFields from "./Forms/AddNewFields.vue";

const props = defineProps({
  invoice_address_differs: String,

  invoice_zip: String,
  invoice_zip_error: String,
  invoice_city: String,
  invoice_city_error: String,
  invoice_street: String,
  invoice_street_error: String,
  invoice_street_number: String,
  invoice_street_number_error: String,
  invoice_address_additional: String,
  large: Boolean,
  compressed: Boolean,
  showHeader: Boolean,
})

const invoiceZipError = ref(props.invoice_zip_error)
const invoiceCityError = ref(props.invoice_city_error)
const invoiceStreetError = ref(props.invoice_street_error)
const invoiceStreetNumberError = ref(props.invoice_street_number_error)

const invoiceAddressDiffers = ref(props.invoice_address_differs)
</script>

<template>
  <div class="col-md-12 pt-4">
    <AddNewFields
        field="invoice_address_differs"
        label="Rechnungsadresse unterscheidet sich von Lieferadresse"
        v-model:addNewFields="invoiceAddressDiffers"
    />
  </div>

  <h2
      class="pt-3 mt-3 pt-md-4 mt-md-4"
      v-if="invoiceAddressDiffers && showHeader"
  >
    Rechnungsadresse
  </h2>
  <hr
      class="border-white pb-2"
      v-if="invoiceAddressDiffers && showHeader"
  >

  <div
      class="pt-4"
      :class="{ 'col-md-2' : compressed,  'col-md-3' : !compressed}"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_zip"
        label="Postleitzahl"
        :value="invoice_zip"
        :has-error="invoiceZipError"
        :is-required="true"
        :large="large"
    />
  </div>
  <div
      class="pt-4"
      :class="{ 'col-md-3' : compressed,  'col-md-9' : !compressed}"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_city"
        label="Ort"
        :value="invoice_city"
        :has-error="invoiceCityError"
        :is-required="true"
        :large="large"
    />
  </div>
  <div
      class="pt-4"
      :class="{ 'col-md-3' : compressed,  'col-md-6' : !compressed}"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_street"
        label="Strasse"
        :value="invoice_street"
        :has-error="invoiceStreetError"
        :is-required="true"
        :large="large"
    />
  </div>
  <div
      class="pt-4"
      :class="{ 'col-md-2' : compressed,  'col-md-3' : !compressed}"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_street_number"
        label="Strassennummer"
        :value="invoice_street_number"
        :has-error="invoiceStreetNumberError"
        :is-required="true"
        :large="large"
    />
  </div>
  <div
      class="pt-4"
      :class="{ 'col-md-2' : compressed,  'col-md-3' : !compressed}"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_address_additional"
        label="Zusatz"
        :value="invoice_address_additional"
        :large="large"
    />
  </div>

</template>

<style scoped>

</style>