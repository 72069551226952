<script setup>
import {ref} from 'vue'
import Text from "./Forms/Text.vue";
import AddNewFields from "./Forms/AddNewFields.vue";

const props = defineProps({
  second_contract_partner: String,
  second_title: String,
  second_salutation: String,
  second_salutation_error: String,
  second_first_name: String,
  second_first_name_error: String,
  second_sure_name: String,
  second_sure_name_error: String,
  required: Boolean,
  compressed: Boolean,
})

const secondSalutationError = ref(props.second_salutation_error)
const secondFirstNameError = ref(props.second_first_name_error)
const secondSureNameError = ref(props.second_sure_name_error)

const secondContractPartner = ref(props.second_contract_partner)

</script>

<template>
  <div class="col-md-12 pt-4">
    <AddNewFields
        field="second_contract_partner"
        label="Zweiter Vertragspartner"
        v-model:addNewFields="secondContractPartner"
    />
  </div>


  <div
      class="pt-4"
      :class="{ 'col-md-3' : compressed,  'col-md-6' : !compressed}"
      v-if="secondContractPartner"
  >
    <label
        for="second_title"
        class="form-label"
    >
      Titel 2
    </label>
    <select
        class="form-select form-select-lg"
        name="second_title"
        id="second_title"
    >
      <option></option>
      <option
          value="Dr."
          :selected="second_title == 'Dr.'"
      >
        Dr.
      </option>
      <option
          value="Dr. Ing."
          :selected="second_title == 'Dr. Ing.'"
      >
        Dr. Ing.
      </option>
      <option
          value="Prof."
          :selected="second_title == 'Prof.'"
      >
        Prof.
      </option>
      <option
          value="Prof. Dr."
          :selected="second_title == 'Prof. Dr.'"
      >
        Prof. Dr.
      </option>
      <option
          value="Dipl.-Ing."
          :selected="second_title == 'Dipl.-Ing.'"
      >
        Dipl.-Ing.
      </option>
      <option
          value="Dipl.-Ing."
          :selected="second_title == 'Dipl.-Ing.'"
      >
        Dipl.-Ing.
      </option>
      <option
          value="Dipl.-Kfm."
          :selected="second_title == 'Dipl.-Kfm.'"
      >
        Dipl.-Kfm.
      </option>
    </select>
  </div>


  <div
      class="pt-4"
      :class="{ 'col-md-3' : compressed,  'col-md-6' : !compressed}"
      v-if="secondContractPartner"
  >
    <label
        for="second_salutation"
        class="form-label"
    >
      Anrede 2 <span v-if="required">*</span>
    </label>
    <select
        class="form-select form-select-lg"
        :class="{ 'is-invalid': secondSalutationError }"
        name="second_salutation"
        id="second_salutation"
        :required="required"
    >
      <option></option>
      <option value="keine_angabe">Keine Angabe</option>
      <option
          value="Frau"
          :selected="second_salutation == 'Frau'"
      >
        Frau
      </option>
      <option
          value="Herr"
          :selected="second_salutation == 'Herr'"
      >
        Herr
      </option>
    </select>
    <div
        id="validationForsecond_salutation"
        class="invalid-feedback"
    >
      Bitte dieses Feld korrekt befüllen.
    </div>
  </div>


  <div
      class="pt-4"
      :class="{ 'col-md-3' : compressed,  'col-md-6' : !compressed}"
      v-if="secondContractPartner"
  >
    <Text
        field="second_first_name"
        label="Vorname 2"
        :has-error="secondFirstNameError"
        :value="second_first_name"
        :is-required="required"
    />
  </div>
  <div
      class="pt-4"
      :class="{ 'col-md-3' : compressed,  'col-md-6' : !compressed}"
      v-if="secondContractPartner"
  >
    <Text
        field="second_sure_name"
        label="Nachname 2"
        :has-error="secondSureNameError"
        :value="second_sure_name"
        :is-required="required"
    />
  </div>
</template>

<style scoped>

</style>