<script setup>
import {ref} from 'vue'
import Date from './Forms/Date.vue';
import Text from './Forms/Text.vue';

const props = defineProps({
  select_termination: String,
  current_supplier: String,
  current_supplier_error: String,
  self_termination_date: String,
  self_termination_date_error: String,
  move_in_date: String,
  move_in_date_error: String,
  required: Boolean,
})

let terminationType = ref(props.select_termination);

const currentSupplierError = ref(props.current_supplier_error)
const selfTerminationDateError = ref(props.self_termination_date_error)
const moveInDateError = ref(props.move_in_date_error)

</script>

<template>
  <div class="form-check col-md-12 ms-3 pt-4">
    <input
        class="form-check-input col-2 col-md-1"
        type="radio"
        name="select_termination"
        id="select_termination-1"
        value="fast"
        v-model="terminationType"
        checked
    >
    <label
        class="form-check-label ps-2 col-10 col-md-11"
        for="select_termination-1"
    >
      Schnellstmöglich
    </label>
  </div>
  <div class="form-check col-md-12 ms-3 pt-4">
    <input
        class="form-check-input col-2 col-md-1"
        type="radio"
        name="select_termination"
        id="select_termination-2"
        value="self"
        v-model="terminationType"
    >
    <label
        class="form-check-label ps-2 col-10 col-md-11"
        for="select_termination-2"
    >
      Ich habe selbst gekündigt
    </label>
  </div>

  <div class="form-check col-md-12 ms-3 pt-4">
    <input
        class="form-check-input col-2 col-md-1"
        type="radio"
        name="select_termination"
        id="select_termination-3"
        value="move-in"
        v-model="terminationType"
    >
    <label
        class="form-check-label ps-2 col-10 col-md-11"
        for="select_termination-3"
    >
      Neueinzug
    </label>
  </div>

  <div
      class="col-md-12 pt-4"
      v-if="terminationType === 'fast'"
  >
    <input
        type="hidden"
        name="termination_next_possibility"
        value="true"
        v-if="terminationType === 'fast'"
    >
    <Text
        v-if="terminationType === 'fast'"
        field="current_supplier"
        label="Aktueller Energielieferant"
        :value="current_supplier"
        :has-error="currentSupplierError"
        :is-required="required"
    ></Text>
  </div>

  <div
      class="col-md-12 pt-4"
      v-if="terminationType === 'self'"
  >
    <input
        type="hidden"
        name="self_termination"
        value="true"
        v-if="terminationType === 'self'"
    >
    <Date
        v-if="terminationType === 'self'"
        field="self_termination_date"
        label="Selbst gekündigt zum"
        :value="self_termination_date"
        :has-error="selfTerminationDateError"
        :is-required="required"
    ></Date>
  </div>


  <div
      class="col-md-12 pt-4"
      v-if="terminationType === 'self'"
  >
    <Text
        v-if="terminationType === 'self'"
        field="current_supplier"
        label="Aktueller Energielieferant"
        :value="current_supplier"
        :is-required="required"
    ></Text>
  </div>


  <div
      class="col-md-12 pt-4"
      v-if="terminationType === 'move-in'"
  >
    <input
        type="hidden"
        name="move_in"
        value="true"
        v-if="terminationType === 'move-in'"
    >
    <Date
        v-if="terminationType === 'move-in'"
        field="move_in_date"
        label="Datum Wohnungsübergabe"
        :value="move_in_date"
        :has-error="moveInDateError"
        :is-required="required"
    ></Date>
  </div>
</template>

<style scoped>

</style>