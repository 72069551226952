<script setup>
import {ref} from 'vue'
import Text from './Forms/Text.vue'
import AddNewFields from "./Forms/AddNewFields.vue";

const props = defineProps({
  bank_account_name_differs: String,

  bank_account_first_name: String,
  bank_account_first_name_error: String,

  bank_account_sure_name: String,
  bank_account_sure_name_error: String,

  bank_account_company: String,
  bank_account_company_error: String,

  large: Boolean,
  compressed: Boolean,
})

const bankAccountFirstNameError = ref(props.bank_account_first_name_error)
const bankAccountSureNameError = ref(props.bank_account_sure_name_error)
const bankAccountCompanyError = ref(props.bank_account_company_error)

const bankAccountNameDiffers = ref(props.bank_account_name_differs)

</script>

<template>
  <div class="col-md-12 pt-4">
    <AddNewFields
        field="bank_account_name_differs"
        label="Der Kontoinhaber unterscheidet sich vom Auftraggeber"
        v-model:addNewFields="bankAccountNameDiffers"
    />
  </div>

  <div
      class="pt-4"
      :class="{ 'col-md-4' : compressed,  'col-md-12' : !compressed}"
      v-if="bankAccountNameDiffers"
  >
    <Text
        field="bank_account_company"
        label="Firma"
        :value="bank_account_company"
        :has-error="bankAccountCompanyError"
        :large="large"
    />
  </div>

  <div
      class="pt-4"
      :class="{ 'col-md-4' : compressed,  'col-md-6' : !compressed}"
      v-if="bankAccountNameDiffers"
  >
    <Text
        field="bank_account_first_name"
        label="Vorname"
        :value="bank_account_first_name"
        :has-error="bankAccountFirstNameError"
        :large="large"
    />
  </div>

  <div
      class="pt-4"
      :class="{ 'col-md-4' : compressed,  'col-md-6' : !compressed}"
      v-if="bankAccountNameDiffers"
  >
    <Text
        field="bank_account_sure_name"
        label="Nachname"
        :value="bank_account_sure_name"
        :has-error="bankAccountSureNameError"
        :large="large"
    />
  </div>

</template>

<style scoped>

</style>